<template>
  <!-- <div style="width: 100%; height: 100%;" v-if="movil">
      <div class="btn-return" @click="ReturnHome">
        <v-icon large color="white">
        mdi-arrow-left-bold
        </v-icon>
        Regresar
      </div>
      <iframe src="https://i.simmer.io/@claseleelee/~ab89c565-5178-f1cf-6d83-c2db170062b9" style="width: 100%; height: 100%; position: absolute;"></iframe>
  </div> -->
    <div>
        <v-row>
            <v-btn icon @click="addColumn()">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
        </v-row>
        <v-row>
            <v-col cols="4" md="4" sm="6" v-for="(items, index) in groupsDragDrop2" :key="index">
            <Container
                group-name="column"
                :get-child-payload="(itemIndex) => getChildPayloadMain(index, itemIndex)"
                @drop="onDrop(index,'groupsDragDrop2', $event)"
            >
                <Draggable v-for="item in items" :key="item.id">
                <div class="draggable-item">
                    {{ item.data }}
                </div>
                </Draggable>
            </Container>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4" md="4" sm="6" v-for="(items, index) in groupsDragDrop" :key="index">
            <Container
              
                group-name="column"
                :get-child-payload="(itemIndex) => getChildPayload(index, itemIndex)"
                @drop="onDrop(index, 'groupsDragDrop', $event)"
            >
                <Draggable v-for="item in items" :key="item.id">
                <div class="draggable-item">
                    {{ item.data }}
                </div>
                </Draggable>
            </Container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import { applyDrag, generateItems } from "../../../components/DragDrop";

let i = 0;

function id() {
  return `item-${++i}`;
}
function generate(num) {
  return generateItems(1, (i) => ({
    id: id(),
    data: `Alumnos  ${num} - ${i + 1}`,
  }));
}
export default {
  name: "Groups",

  components: { Container, Draggable },

  data() {
    return {
        namegroups:[
            {id: "", name: "grupo 1"},
            {id: "", name: "grupo 2"},
        ],
        groupsDragDrop: [
            [
                    { id: "1231qwasda", data: "alumno 1"},
                    { id: "1231qwasda2", data: "alumno 2"},
                    { id: "1231qwasda3", data: "alumno 3"}
                ],
            [
                    { id: "1231qwasda5", data: "alumno 5"},
                    { id: "1231qwasda6", data: "alumno 6"},
                    { id: "1231qwasda7", data: "alumno 7"},
                    { id: "1231qwasda8", data: "alumno 8"},
                    { id: "1231qwasda9", data: "alumno 9"},
                    { id: "1231qwasda10", data: "alumno 10"}
                ]
        ],
        groupsDragDrop2: [
            [
                    { id: "1231qwasda0", data: "alumno 10"},
                    { id: "1231qwasda20", data: "alumno 20"},
                    { id: "1231qwasda30", data: "alumno 30"}
                ],
        ],
        logs: {
            "get-child-payload": true,

            drop: true,
        },
        RemovedElement: null,
        AddedElement: null
    };
  },

  methods: {
    // onDrop(collection, dropResult) {
    //     console.log(collection);
    //     console.log(dropResult);
    //     if (dropResult.removedIndex != null && dropResult.addedIndex != null ) {
            
    //     }else{
    //         if (dropResult.removedIndex != null) {
                
    //         }
    //         this[collection] = applyDrag(this[collection], dropResult);
    //     }
    // },
     onDrop(groupIndex,collection, dropResult) {
        console.log(groupIndex);
        console.log(dropResult);
        if (dropResult.removedIndex == null && dropResult.addedIndex == null ) {
            
        }else{
            if (dropResult.removedIndex != null) {
                // fila donde se quito
                this.RemovedElement = dropResult.removedIndex;
            }
            if (dropResult.addedIndex != null ) {
                // fila donde se agrego
                this.AddedElement = dropResult.addedIndex;
            }
            if (this.RemovedElement != null && this.AddedElement != null) {
                //s aqui agregar el guardado en firebase
                console.log("aqui se agrego el elemento");
                this.RemovedElement = null;
                this.AddedElement = null;
            }
            console.log(this[collection][groupIndex]);
            let result= applyDrag(this[collection][groupIndex], dropResult);
            this.$set(this[collection], groupIndex, result);
            // if (grupo ==1) {
            //     let result = applyDrag(this.groupsDragDrop[groupIndex], dropResult);
            //     this.$set(this.groupsDragDrop, groupIndex, result);
            //     this.log("drop", dropResult);    
            // }else{
            //     let result = applyDrag(this.groupsDragDrop2[groupIndex], dropResult);
            //     this.$set(this.groupsDragDrop2, groupIndex, result);
            //     this.log("drop", dropResult);
            // }
        }
    },
    getChildPayloadMain(groupIndex, itemIndex) {
        this.log("get-child-payload", groupIndex, itemIndex);
        return this.groupsDragDrop2[groupIndex][itemIndex];
    },
    getChildPayload(groupIndex, itemIndex) {
      this.log("get-child-payload", groupIndex, itemIndex);
      return this.groupsDragDrop[groupIndex][itemIndex];
    },
    addColumn() {
      this.groupsDragDrop.push(generate(this.groupsDragDrop.length + 1));
      this.flags.push({ drop: true, animate: true });
    },

    removeColumn() {
      this.groupsDragDrop.pop();
      this.flags.pop();
    },

    log(name, ...args) {
      if (this.logs[name]) {
        this.logPayload ? console.log(name, ...args) : console.log(name);
      }
    },
  },
};
</script>

<style scoped>
.groupsDragDrop {
  display: flex;
  justify-content: stretch;
}

.group {
  border: 2px solid rgb(0, 0, 0);
  flex: 1;
  padding: 5px;
  margin: 5px;
}



code[class*=language-], pre[class*=language-] {
    color: #ccc;
    background: none;
    font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
pre[class*=language-] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

.draggable-item {
    flex-direction: row!important;
    justify-content: space-between;
    text-align: center;
    background-color: #fff;
    border: 1px solid rgba(89, 123, 160, .667);
    border-left: 5px solid #42b883;
    margin: 3px;
    padding: 8px 0;
    cursor: pointer;
    border-radius: 4px;
}
.draggable-item-horizontal {
    height: 300px;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgba(89, 123, 160, .667);
    border-top: 5px solid #42b883;
    border-radius: 4px;
    transition: border-color .2s linear;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
}
.draggable-item-horizontal div, .draggable-item div {
    color: red;
}
.dragging {
    background-color: #ff0;
}
.card-scene {
    max-width: 740px;
    margin: 0 auto;
    padding: 2rem 2.5rem;
}
.card-container {
    padding: 10px;
    margin: 5px;
    background-color: #f3f3f3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 1px 1px rgba(0, 0, 0, .24);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
    transition: border-color .2s linear;
}
.card-text, .card h3 {
    color: #f2f2f2;
}
.card h3 {
    margin: 0;
}
.card-column-header {
    font-size: 18px;
}
.column-drag-handle {
    cursor: move;
    padding: 5px;
}
.card-ghost {
    transition: transform .18s ease;
    transform: rotate(5deg);
}
.card-ghost-drop {
    transition: transform .18s ease-in-out;
    transform: rotate(0deg);
}
.opacity-ghost {
    transition: all .18s ease;
    opacity: .8;
    background-color: #6495ed;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .3);
}
.opacity-ghost-drop {
    opacity: 1;
    background-color: #fff;
    box-shadow: 3px 3px 10px 3px transparent;
}
.form-docs {
    display: flex;
}
.form {
    flex: 3;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 6px;
}
.form-fields-panel {
    flex: 1;
    margin-right: 50px;
}
.form-ghost {
    transition: .18s ease;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, .08);
}
.form-ghost-drop {
    box-shadow: 0 0 2px 5px transparent;
}
.drop-preview {
    margin: 5px;
}
.cards-drop-preview, .drop-preview {
    background-color: rgba(150, 150, 200, .1);
    border: 1px dashed #abc;
}
.cards-drop-preview {
    margin: 5px 45px 5px 5px;
}
.form-field {
    height: 50px;
    width: 250px;
    line-height: 30px;
    vertical-align: middle;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    border-color: #fff #eee #ddd;
    border-style: solid;
    border-width: 1px;
    cursor: move;
}
.form-line {
    border: 1px solid #bcdae8;
    padding: 20px 30px;
    border-radius: 6px;
    transition: all .3s ease;
    transition-property: border-color, background-color;
    cursor: move;
}
.form-line, .form-line.selected {
    background-color: #f8f9fa;
}
#nprogress {
    pointer-events: none;
}
#nprogress .bar {
    background: #3eaf7c;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #3eaf7c, 0 0 5px #3eaf7c;
    opacity: 1;
    transform: rotate(3deg) translateY(-4px);
}
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}
#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border-color: #3eaf7c transparent transparent #3eaf7c;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner .4s linear infinite;
    animation: nprogress-spinner .4s linear infinite;
}
.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}
.nprogress-custom-parent #nprogress .bar, .nprogress-custom-parent #nprogress .spinner {
    position: absolute;
}
@-webkit-keyframes nprogress-spinner {
    0% {
    transform: rotate(0deg);
}
to {
    transform: rotate(1turn);
}
}@keyframes nprogress-spinner {
    0% {
    transform: rotate(0deg);
}
to {
    transform: rotate(1turn);
}
}html {
    scroll-behavior: smooth;
}
.icon.outbound {
    color: #aaa;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.page-edit {
    max-width: 740px;
    margin: 0 auto;
    padding: 2rem 2.5rem;
}
@media (max-width:959px) {
    .page-edit {
    padding: 2rem;
}
}@media (max-width:419px) {
    .page-edit {
    padding: 1.5rem;
}
}.page-edit {
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: auto;
}
.page-edit .edit-link {
    display: inline-block;
}
.page-edit .edit-link a {
    color: #4e6e8e;
    margin-right: .25rem;
}
.page-edit .last-updated {
    float: right;
    font-size: .9em;
}
.page-edit .last-updated .prefix {
    font-weight: 500;
    color: #4e6e8e;
}
.page-edit .last-updated .time {
    font-weight: 400;
    color: #767676;
}
@media (max-width:719px) {
    .page-edit .edit-link {
    margin-bottom: .5rem;
}
.page-edit .last-updated {
    font-size: .8em;
    float: none;
    text-align: left;
}
}.page-nav {
    max-width: 740px;
    margin: 0 auto;
    padding: 2rem 2.5rem;
}
@media (max-width:959px) {
    .page-nav {
    padding: 2rem;
}
}@media (max-width:419px) {
    .page-nav {
    padding: 1.5rem;
}
}.page-nav {
    padding-top: 1rem;
    padding-bottom: 0;
}
.page-nav .inner {
    min-height: 2rem;
    margin-top: 0;
    border-top: 1px solid #eaecef;
    padding-top: 1rem;
    overflow: auto;
}
.page-nav .next {
    float: right;
}
.page {
    padding-bottom: 2rem;
    display: block;
}
.sidebar-group .sidebar-group {
    padding-left: .5em;
}
.sidebar-group:not(.collapsable) .sidebar-heading:not(.clickable) {
    cursor: auto;
    color: inherit;
}
.sidebar-group.is-sub-group {
    padding-left: 0;
}
.sidebar-group.is-sub-group>.sidebar-heading {
    font-size: .95em;
    line-height: 1.4;
    font-weight: 400;
    padding-left: 2rem;
}
.sidebar-group.is-sub-group>.sidebar-heading:not(.clickable) {
    opacity: .5;
}
.sidebar-group.is-sub-group>.sidebar-group-items {
    padding-left: 1rem;
}
.sidebar-group.is-sub-group>.sidebar-group-items>li>.sidebar-link {
    font-size: .95em;
    border-left: none;
}
.sidebar-group.depth-2>.sidebar-heading {
    border-left: none;
}
.sidebar-heading {
    color: #2c3e50;
    transition: color .15s ease;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 700;
    padding: .35rem 1.5rem .35rem 1.25rem;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-left: .25rem solid transparent;
}
.sidebar-heading.open, .sidebar-heading:hover {
    color: inherit;
}
.sidebar-heading .arrow {
    position: relative;
    top: -.12em;
    left: .5em;
}
.sidebar-heading.clickable.active {
    font-weight: 600;
    color: #3eaf7c;
    border-left-color: #3eaf7c;
}
.sidebar-heading.clickable:hover {
    color: #3eaf7c;
}
.sidebar-group-items {
    transition: height .1s ease-out;
    font-size: .95em;
    overflow: hidden;
}
.sidebar .sidebar-sub-headers {
    padding-left: 1rem;
    font-size: .95em;
}
a.sidebar-link {
    font-size: 1em;
    font-weight: 400;
    display: inline-block;
    color: #2c3e50;
    border-left: .25rem solid transparent;
    padding: .35rem 1rem .35rem 1.25rem;
    line-height: 1.4;
    width: 100%;
    box-sizing: border-box;
}
a.sidebar-link:hover {
    color: #3eaf7c;
}
a.sidebar-link.active {
    font-weight: 600;
    color: #3eaf7c;
    border-left-color: #3eaf7c;
}
.sidebar-group a.sidebar-link {
    padding-left: 2rem;
}
.sidebar-sub-headers a.sidebar-link {
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-left: none;
}
.sidebar-sub-headers a.sidebar-link.active {
    font-weight: 500;
}
.sidebar ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.sidebar a {
    display: inline-block;
}
.sidebar .nav-links {
    display: none;
    border-bottom: 1px solid #eaecef;
    padding: .5rem 0 .75rem;
}
.sidebar .nav-links a {
    font-weight: 600;
}
.sidebar .nav-links .nav-item, .sidebar .nav-links .repo-link {
    display: block;
    line-height: 1.25rem;
    font-size: 1.1em;
    padding: .5rem 0 .5rem 1.5rem;
}
.sidebar>.sidebar-links {
    padding: 1.5rem 0;
}
.sidebar>.sidebar-links>li>a.sidebar-link {
    font-size: 1.1em;
    line-height: 1.7;
    font-weight: 700;
}
.sidebar>.sidebar-links>li:not(:first-child) {
    margin-top: .75rem;
}
@media (max-width:719px) {
    .sidebar .nav-links {
    display: block;
}
.sidebar .nav-links .dropdown-wrapper .nav-dropdown .dropdown-item a.router-link-active:after {
    top: calc(1rem - 2px);
}
.sidebar>.sidebar-links {
    padding: 1rem 0;
}
}.kanban[data-v-2fcb2b94] {
    justify-content: stretch;
    width: 100%;
    height: 100%}
.kanban[data-v-2fcb2b94], .kanban__move-icon[data-v-2fcb2b94] {
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.kanban__move-icon[data-v-2fcb2b94] {
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: .9rem;
    color: #b0b0b0;
}
.kanban__move-icon span[data-v-2fcb2b94] {
    margin-left: 10px;
}
.kanban__move-icon svg[data-v-2fcb2b94] {
    width: 20px;
    height: 20px;
}
.kanban__drop-preview[data-v-2fcb2b94] {
    position: relative;
    margin: 10px;
    background-color: #edf1f3;
    border: 2px dashed rgba(0, 0, 0, .059);
    border-radius: 4px;
}
.kanban__drop-preview[data-v-2fcb2b94]:before {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #b0b0b0;
    transform: translate(-50%, -50%);
}
.kanban__drop-preview[data-v-2fcb2b94]:after {
    position: absolute;
    top: 50%;
    left: 27%;
    width: 20px;
    height: 20px;
    content: "";
    transform: translate(-50%, -50%);
}
.kanban__description[data-v-2fcb2b94], .kanban__icon[data-v-2fcb2b94] {
    display: flex;
    align-items: center;
}
.kanban__icon[data-v-2fcb2b94] {
    justify-content: center;
    background-color: #edf5f7;
    padding: .5rem;
    border-radius: 8px;
}
.kanban__title[data-v-2fcb2b94] {
    margin: 0 0 0 5px;
    font-size: 1rem;
    font-weight: 700;
    color: #555;
}
.kanban__quantity[data-v-2fcb2b94] {
    margin-top: 0;
    font-size: small;
    color: #b0b0b0;
    text-align: right;
}
.kanban__quantity--inner-count[data-v-2fcb2b94] {
    margin: 1rem 1rem 0;
    padding-top: .5rem;
}
.kanban__column[data-v-2fcb2b94] {
    flex: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 6px;
    transition: height .5s ease;
    border: 1px solid #e0e0e0;
}
.kanban__column[data-v-2fcb2b94]:not(:first-of-type) {
    margin-left: 15px;
}
.kanban__column--no-border[data-v-2fcb2b94] {
    border: none;
}
.kanban__header[data-v-2fcb2b94] {
    margin-bottom: 15px;
    background-color: #fff;
}
.kanban__content[data-v-2fcb2b94] {
    position: relative;
}
.kanban__skeleton[data-v-2fcb2b94] {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-right: 15px;
    background-color: #f8f8f8;
    border-radius: 4px;
    overflow: auto;
}
.kanban__skeleton-container[data-v-2fcb2b94] {
    width: 100%}
.kanban__skeleton-column[data-v-2fcb2b94] {
    flex: 1;
    height: calc(100% - 30px);
    padding: 10px;
    margin-left: 15px;
    background-color: #e9e9e9;
    border-radius: 6px;
}
.kanban__skeleton-card[data-v-2fcb2b94] {
    position: relative;
    height: 100px;
    padding: 15px;
    margin-top: 16px;
    background-color: #f5f5f5;
    border-radius: 6px;
}
.kanban__skeleton-card--one[data-v-2fcb2b94] {
    position: absolute;
    top: 8px;
}
.kanban__skeleton-card--two[data-v-2fcb2b94] {
    position: absolute;
    top: 8px;
    right: 10px;
}
.kanban__skeleton-card--three[data-v-2fcb2b94] {
    position: absolute;
    top: 30px;
}
.kanban__skeleton-card--four[data-v-2fcb2b94] {
    position: absolute;
    bottom: 2rem;
}
.kanban__skeleton-card--five[data-v-2fcb2b94] {
    position: absolute;
    left: 1rem;
    bottom: .5rem;
}
.draggable-item-horizontal[data-v-2fcb2b94], .draggable-item[data-v-2fcb2b94], .item[data-v-2fcb2b94] {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid transparent;
    border-left: 5px solid #e0e0e0;
    border-radius: 4px;
    transition: border-color .2s linear;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
}
.item .card[data-v-2fcb2b94] {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.item .card small[data-v-2fcb2b94] {
    color: #838383;
}
.item.open[data-v-2fcb2b94] {
    border-left: 5px solid #f3c1c1;
}
.item.executing[data-v-2fcb2b94] {
    border-left: 5px solid #c4ebaf;
}
.item.finished[data-v-2fcb2b94] {
    border-left: 5px solid #b3d5e9;
}
.item.paralised[data-v-2fcb2b94] {
    border-left: 5px solid #dc3545;
}
.item.review[data-v-2fcb2b94] {
    border-left: 5px solid #e0e0e0;
}
.badge[data-v-2fcb2b94] {
    margin: 1rem 0;
    cursor: pointer;
}
.label[data-v-2fcb2b94] {
    color: #838383;
}
.kanban-action[data-v-2fcb2b94] {
    display: flex;
    justify-content: space-between;
}
.kanban-button[data-v-2fcb2b94] {
    max-width: 130px;
    pointer-events: none;
    border: none;
    color: #f7f7f7;
    padding: 1px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 100px;
}
.kanban-button.open[data-v-2fcb2b94] {
    background-color: #f3c1c1;
}
.kanban-button.executing[data-v-2fcb2b94] {
    background-color: #c4ebaf;
}
.kanban-button.finished[data-v-2fcb2b94] {
    background-color: #b3d5e9;
}
.kanban-button.paralised[data-v-2fcb2b94] {
    background-color: #dc3545;
}
.kanban-button.review[data-v-2fcb2b94] {
    background-color: #e0e0e0;
}
.kanban[data-v-49ec39b7] {
    justify-content: stretch;
    width: 100%;
    height: 100%}
.kanban[data-v-49ec39b7], .kanban__move-icon[data-v-49ec39b7] {
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.kanban__move-icon[data-v-49ec39b7] {
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: .9rem;
    color: #b0b0b0;
}
.kanban__move-icon span[data-v-49ec39b7] {
    margin-left: 10px;
}
.kanban__move-icon svg[data-v-49ec39b7] {
    width: 20px;
    height: 20px;
}
.kanban__drop-preview[data-v-49ec39b7] {
    position: relative;
    margin: 10px;
    background-color: #edf1f3;
    border: 2px dashed rgba(0, 0, 0, .059);
    border-radius: 4px;
}
.kanban__drop-preview[data-v-49ec39b7]:before {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #b0b0b0;
    transform: translate(-50%, -50%);
}
.kanban__drop-preview[data-v-49ec39b7]:after {
    position: absolute;
    top: 50%;
    left: 27%;
    width: 20px;
    height: 20px;
    content: "";
    transform: translate(-50%, -50%);
}
.kanban__description[data-v-49ec39b7], .kanban__icon[data-v-49ec39b7] {
    display: flex;
    align-items: center;
}
.kanban__icon[data-v-49ec39b7] {
    justify-content: center;
    background-color: #edf5f7;
    padding: .5rem;
    border-radius: 8px;
}
.kanban__title[data-v-49ec39b7] {
    margin: 0 0 0 5px;
    font-size: 1rem;
    font-weight: 700;
    color: #555;
}
.kanban__quantity[data-v-49ec39b7] {
    margin-top: 0;
    font-size: small;
    color: #b0b0b0;
    text-align: right;
}
.kanban__quantity--inner-count[data-v-49ec39b7] {
    margin: 1rem 1rem 0;
    padding-top: .5rem;
}
.kanban__column[data-v-49ec39b7] {
    flex: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    border-radius: 6px;
    transition: height .5s ease;
    border: 1px solid #e0e0e0;
}
.kanban__column[data-v-49ec39b7]:not(:first-of-type) {
    margin-left: 15px;
}
.kanban__column--no-border[data-v-49ec39b7] {
    border: none;
}
.kanban__header[data-v-49ec39b7] {
    margin-bottom: 15px;
    background-color: #fff;
}
.kanban__content[data-v-49ec39b7] {
    position: relative;
}
.kanban__skeleton[data-v-49ec39b7] {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-right: 15px;
    background-color: #f8f8f8;
    border-radius: 4px;
    overflow: auto;
}
.kanban__skeleton-container[data-v-49ec39b7] {
    width: 100%}
.kanban__skeleton-column[data-v-49ec39b7] {
    flex: 1;
    height: calc(100% - 30px);
    padding: 10px;
    margin-left: 15px;
    background-color: #e9e9e9;
    border-radius: 6px;
}
.kanban__skeleton-card[data-v-49ec39b7] {
    position: relative;
    height: 100px;
    padding: 15px;
    margin-top: 16px;
    background-color: #f5f5f5;
    border-radius: 6px;
}
.kanban__skeleton-card--one[data-v-49ec39b7] {
    position: absolute;
    top: 8px;
}
.kanban__skeleton-card--two[data-v-49ec39b7] {
    position: absolute;
    top: 8px;
    right: 10px;
}
.kanban__skeleton-card--three[data-v-49ec39b7] {
    position: absolute;
    top: 30px;
}
.kanban__skeleton-card--four[data-v-49ec39b7] {
    position: absolute;
    bottom: 2rem;
}
.kanban__skeleton-card--five[data-v-49ec39b7] {
    position: absolute;
    left: 1rem;
    bottom: .5rem;
}
.draggable-item-horizontal[data-v-49ec39b7], .draggable-item[data-v-49ec39b7], .item[data-v-49ec39b7] {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid transparent;
    border-left: 5px solid #e0e0e0;
    border-radius: 4px;
    transition: border-color .2s linear;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1), 0 0 1px rgba(0, 0, 0, .1);
}
.item .card[data-v-49ec39b7] {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.item .card small[data-v-49ec39b7] {
    color: #838383;
}
.item.open[data-v-49ec39b7] {
    border-left: 5px solid #f3c1c1;
}
.item.executing[data-v-49ec39b7] {
    border-left: 5px solid #c4ebaf;
}
.item.finished[data-v-49ec39b7] {
    border-left: 5px solid #b3d5e9;
}
.item.paralised[data-v-49ec39b7] {
    border-left: 5px solid #dc3545;
}
.item.review[data-v-49ec39b7] {
    border-left: 5px solid #e0e0e0;
}
.badge[data-v-49ec39b7] {
    margin: 1rem 0;
    cursor: pointer;
}
.label[data-v-49ec39b7] {
    color: #838383;
    margin-bottom: 5px;
}
.kanban-action[data-v-49ec39b7] {
    display: flex;
    justify-content: space-between;
}
.kanban-button[data-v-49ec39b7] {
    max-width: 130px;
    pointer-events: none;
    border: none;
    color: #f7f7f7;
    padding: 1px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 100px;
}
.kanban-button.open[data-v-49ec39b7] {
    background-color: #f3c1c1;
}
.kanban-button.executing[data-v-49ec39b7] {
    background-color: #c4ebaf;
}
.kanban-button.finished[data-v-49ec39b7] {
    background-color: #b3d5e9;
}
.kanban-button.paralised[data-v-49ec39b7] {
    background-color: #dc3545;
}
.kanban-button.review[data-v-49ec39b7] {
    background-color: #e0e0e0;
}
.kanban-cards[data-v-49ec39b7] {
    display: flex;
    justify-content: center;
    margin: 5px 0;
}
.copy {
    display: flex;
    gap: 1rem;
}
.item {
    flex: 1;
}
.limited-height[data-v-576f461c] {
    height: 60vh;
    overflow: auto;
}
.controls[data-v-aca1d1d4] {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
}
.controls .title[data-v-aca1d1d4] {
    align-self: flex-start;
    margin: 0 1rem;
}
.controls .buttons[data-v-aca1d1d4] {
    align-self: flex-end;
    margin: 1rem;
}
.controls .actions[data-v-aca1d1d4] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 1rem;
}
.controls>div[data-v-aca1d1d4] {
    padding-top: 1em;
}
.buttons[data-v-aca1d1d4], .column-actions[data-v-aca1d1d4] {
    display: flex;
    gap: 1rem;
}
.column-actions[data-v-aca1d1d4] {
    justify-content: space-evenly;
}


</style>